import { useRouter } from 'next/router';

export default function Custom404() {
    const router = useRouter();

    return (
        <div style={{ display: 'flex', alignItems: 'center', height: '100vh' }}>
            <div
                style={{
                    margin: 'auto',
                    textAlign: 'left',
                    maxWidth: '524px',
                    marginTop: '8rem',
                    padding: '2rem'
                }}
            >
                <h2
                    style={{
                        color: '#68748260',
                        marginBottom: '4px',
                        opacity: 0.6
                    }}
                >
                    404
                </h2>
                <h2 style={{ fontSize: '54px', marginBottom: '4px' }}>🚧</h2>
                <h2
                    style={{
                        fontSize: '18px',
                        fontWeight: 500,
                        marginBottom: '12px'
                    }}
                >
                    Oh snap! Looks like the page you tried to access doesn't exist.
                </h2>
                <button
                    onClick={() => {
                        router.push('/');
                    }}
                    style={{
                        marginTop: '16px',
                        color: '#FFF',
                        fontWeight: 'bold',
                        boxShadow: '',
                        backgroundColor: '#0078FF',
                        borderRadius: '8px',
                        boxShadow: '0 14px 24px -10px rgba(0,120,255,0.60)',
                        fontWeight: '600'
                    }}
                    className={'button primary is-primary is-medium'}
                >
                    Back to landing page
                </button>
            </div>
        </div>
    );
}
